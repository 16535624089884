.SubCategory {
    width: 100%;
    margin: auto;
    font-family: Gibson-Bold;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
}

.SubCategory.ChallengesSubCategory {
    border-bottom: 2px solid  rgb(107, 55, 124,0.3);
}

.SubCategory.SkillsSubCategory {
    border-bottom: 2px solid rgb(0, 116, 172,0.3);
}

.SubCategory.ToddlersSubCategory {
    border-bottom: 2px solid  rgb(235, 106, 57,0.3);
}

.SubCategory div:first-child {
    width: 40%;
 }

.SubCategory img {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 20px;
}

.SubCategory div:nth-child(2) {
    width: 60%;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.SubCategory div p {
    word-wrap: break-word;
}

ul.task-groups{
    width: 100%;
    height: 100%;
    display: hide;
    margin: auto;
    list-style: none;
}
