.Home .Button {
    display: flex;
    flex-direction: column;
}

.Button button {
    font-size: 1.25em;
    color: white;
    font-family:  Gibson-Bold;
    border: none;
    border-radius: 20px;
    padding: 40px 20px;
    margin-top: 20px;
    vertical-align: top;
}

.Button button img {
    width: 200px;
    height: 200px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
}

.Button button p {
    padding: 10px 0;
    margin: 0 -20px;
    text-transform: uppercase;
}

.Button .description {
    text-align: center;
    width: 200px;
    margin-top: 20px;
    color: black;
    font-family: Gibson;
    font-size: 16px;
  font-weight: 600;
}

.AtSchool .Button .description,
.AtNurseries .Button .description
 {
    margin: auto;
}

@media (min-width: 768px) {
    .Button button img {
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }

    .Button .description {
        font-size: 16px;
        width: 200px;
        color: white
    }

    .Button .description,
    .AtSchool .Button .description,
    .AtNurseries .Button .description
     {
        margin-top: 20px;
        color: black
     }

     .AtSchool .Button button {
        margin: 50px 50px 0 50px;
    }
}

@media (min-width: 1200px) {
    .Button button img {
        width: 130px;
        height: 130px;
        display: block;
        margin: auto;
    }

    .AtSchool .Button button img,
    .AtNurseries .Button button img
     {
        width: 200px;
        height: 200px;
        display: block;
        margin: auto;
    }

    .Button .description {
        font-size: 15px;
        width: 200px;
        text-align: center;
        color: black
    }

    .Button button {
        margin-top: 50px;
    }
}


