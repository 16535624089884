.Task {
  font-family: Gibson-Bold;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
}

.Task.ChallengesTask {
  border-bottom: 2px solid rgb(107, 55, 124,0.3);
}

.Task.SkillsTask {
  border-bottom: 2px solid rgb(0, 116, 172,0.3);
}

.Task.ToddlersTask {
  border-bottom: 2px solid  rgb(235, 106, 57,0.3);
}

.Task div:first-child {
  width: 40%;
  display: flex;
  justify-content: center;
}

.Task img {
  min-width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}

.Task div:nth-child(2) {
  width: 150px;
  padding-left: 20px;
  word-wrap: break-word;
}