.Home div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
}

.Buttons div {
    margin-top: 0%;
    align-items: center;
    vertical-align: text-top;
}

.Home h1 {
    width: 50%;
    font-family: Gibson-Bold;
    font-style: italic;
    font-size: 45px;
    color: #0EB1AF;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    padding-top: 60px;
}

.Home a {
    text-decoration: none;
}

.feedback-button {
    background-color: #F9B200; /* Keltainen tausta */
    color: #ffffff; /* Tekstin väri */
    padding: 10px 40px; /* Tyhjä tila ympärillä */
    border: none; /* Ei reunaa */
    border-radius: 5px; /* Pyöristetyt reunat */
    text-decoration: none; /* Ei alleviivausta */
    display: inline-block; /* Rivi-elementti */
    cursor: pointer; /* Kursori muuttuu painikkeen yläpuolella */
    font-size: 20px; /* Tekstin koko */
    font-weight: 600;
    text-align: center; /* Tekstin tasaus keskelle */
    margin: 4px 2px; /* Marginaali ympärillä */
    transition: background-color 0.3s; /* Animaatio taustavärin muutokselle */
  }
  
  .feedback-button:hover {
    background-color: #0EB1AF; /* Taustaväri muuttuu hiiren ollessa elementin päällä */
  }
  
  .feedback-note {
    text-align: center; /* Keskittää tekstin */
    margin-top: -10px; /* Nostaa tekstin ylöspäin, säädä arvoa tarpeen mukaan */
    font-style: italic;
  }
  

@media (min-width: 768px) {
    .Home div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .Home h1 {
        width: 50%;
        color: white
    }

    .Home {
        background-image: url("/public/image/background.png");
        background-size: cover;
        min-height: 100vh;
    }
}

@media (min-width: 1200px) {
    .Home div {
        width: 45%;
    }

    .Home h1 {
        color: #0EB1AF
    }

    .Home {
    background-image: url("/public/image/bg site geego.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 100vh;
}
}
