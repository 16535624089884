.Video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    font-family: Gibson-Bold;
    display: flex;
}

/* .Video video {
    width: 30%;
} */

.Video div {
    width: 33%;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

/* .Video i.fa-solid {
    display: block;
    width: 5%;
    height: 5%;
    font-size: 30px;
    color: rgb(0, 0, 0, 0.5);
}

.Video i.fa-xmark {
    position: fixed;
    top: 0;
    right: 0;
} */


/* .Video h4,
.Video p {
    padding-left: 10px;
}  */

.Video button.closeButton {
    position: fixed;
    margin-right:50px;
    top: 15px;
    right: 0;
    display: block;
    width: 5%;
    height: 5%;
    font-size: 20px;
    color:#bd3232;
}

.Video h4 {
    padding-left: 30px;
}