.TaskGroup {
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 10px;
    border-radius: 5%;
    display: flex;
}

.TaskGroup.ChallengesTaskGroup {
    background-color: rgb(128, 0, 128, 0.1);
}

.TaskGroup.SkillsTaskGroup {
    background-color: rgb(0, 0, 255, 0.1);
}

.TaskGroup div:first-child {
    width: 40%;
}

.TaskGroup img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
}

.TaskGroup div:nth-child(2) {
    width: 60%;
    font-family: Gibson-Bold;
    padding-left: 20px;
}

.TaskGroup div:nth-child(2) p {
    padding-left: 20px;
}

div a {
    text-decoration: none;
    color: black;
}

div a:hover {
    color: black;
}